/* eslint-disable no-debugger */
import Vue from 'vue';
import store from './store';
import VueRouter from 'vue-router';
//import {routes} from './router.buffet';
import './assets/css/main.css';
import i18n from './i18n';
import { Loading } from 'element-ui';
import Vue2TouchEvents from 'vue2-touch-events';
import smoothscroll from 'smoothscroll-polyfill';

import VueSimpleMarkdown from 'vue-simple-markdown';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarDays,
  faCalendar,
  faCircleCheck,
  faO,
  faPlus,
  faMinus,
  faCartShopping,
  faBars,
  faXmark,
  faArrowDown,
  faAngleLeft,
  faArrowRotateRight,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faCheck,
  faBurger,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faCalendarDays,
  faCalendar,
  faCircleCheck,
  faO,
  faPlus,
  faMinus,
  faCartShopping,
  faBars,
  faXmark,
  faArrowDown,
  faAngleLeft,
  faArrowRotateRight,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faCheck,
  faBurger
);

const config = require('./config.' + process.env.NODE_ENV).default;

const App = () => import(`./App.${config.app}.vue`);
const appRouter = () => import(`./router.default`);

Vue.use(VueSimpleMarkdown);

smoothscroll.polyfill();

Vue.config.productionTip = false;

Vue.use(Vue2TouchEvents);
Vue.use(VueRouter);
Vue.component('font-awesome-icon', FontAwesomeIcon);

(async () => {
  const routes = (await appRouter()).routes;
  const router = new VueRouter({ routes });

  router.beforeEach((to, from, next) => {
    // scroll to top on each page change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    next();
  });

  Vue.use(Loading.directive);
  Vue.prototype.$loading = Loading.service;

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
})();
