import {getAuthorizedApiCall} from './http';

//export const getCart = (token, id) => getAuthorizedApiCall(token, 'carts/' + id);

export const saveCart = (token, cart) => getAuthorizedApiCall(token, 'carts' + (cart.id ? '/' + cart.id : ''), {
	method: cart.id ? 'PATCH' : 'POST',
	data: cart
});

export const getOrders = token => getAuthorizedApiCall(token, 'foodOrders').then(res => res.reverse());
export const getPendingOrders = token => getAuthorizedApiCall(token, 'active-foodOrders');

// TODO: use this below, when BE can handle filter option
//export const getOrders = token => getAuthorizedApiCall(token, 'foodOrders?filter={"order":"date DESC"}');

export const getLastOrder = token => getOrders(token).then(res => res[0]);
// TODO: use this below, when BE can handle filter option
//export const getLastOrder = token => getAuthorizedApiCall(token, 'foodOrders?filter={"limit":1,"order":"date DESC"}');

export const getOrderById = (token, id) => getAuthorizedApiCall(token, 'foodOrders/' + id);

export const getBalance = token => getAuthorizedApiCall(token, 'payments/balance');

export const cancelOrder = (token, id) => getAuthorizedApiCall(token,'foodOrders/' + id, {
	method: 'PATCH',
	data: { status: 'orderCanceled' }
});

export const getMe = token => getAuthorizedApiCall(token, 'accounts/me');

export const savePayment = (token, cartId, paymentType) => getAuthorizedApiCall(token, 'payments', {
	method: 'POST',
	data: {
		cartId,
		paymentType
	}
});
