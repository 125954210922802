import { apiCall, getAuthorizedApiCall } from './http';

export const getInfoPages = (mvmId) => getAuthorizedApiCall(mvmId, `page-groups/?filter={"where":{"targets":"foodApp"}}`);
export const getInfoPageById = (mvmId, id) => getAuthorizedApiCall(mvmId, `pages/${id}`);

export const getBuffets = (mvmId) => getAuthorizedApiCall(mvmId, `client-buffets`);
//export const getBuffets = (mvmId) => getAuthorizedApiCall(mvmId, `buffets`);
export const getBuffetById = (id, params = '') => apiCall(`buffets/${id}${params}`);
export const getBuffetStatusById = (id) => apiCall('buffets/' + id + '/status');
export const getSectorLocations = () => apiCall('buffet-location-groups');

export const postReg = (mvmId, premiumType, email, name, locale) =>
  apiCall(`customers`, {
    method: 'POST',
    data: {
      mvmId: mvmId,
      premium_type: premiumType,
      email: email,
      full_name: name,
      locale: locale,
    },
  });

export const postRegNone = (mvmId, email, name, locale) =>
  apiCall(`customers`, {
    method: 'POST',
    data: {
      mvmId: mvmId,
      email: email,
      full_name: name,
      locale: locale,
    },
  });

export const postRegNoneNew = (mvmId, email, name, country, city, postalcode, address, push_token, locale) =>
  apiCall(`customers`, {
    method: 'POST',
    data: {
      mvmId,
      email,
      full_name: name,
      country,
      city,
      postalcode,
      address,
      push_token,
      locale,
    },
  });
