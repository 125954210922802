import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';
import { getBuffets, getSectorLocations } from './functions/api/content';
import moment from 'moment';
import { getPendingOrders } from './functions/api/payment';
import { bytesToBase64 } from './base64';
const config = require('./config.' + process.env.NODE_ENV).default;
moment.locale('hu');

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const getInitialState = () => ({
  appLoading: true,
  viewedPages: [],
  userToken: '',
  userId: '',
  mvmId: '',
  embedded: '0',
  selectedBuffetGroup: '',
  navigationVisible: true,
  loading: false,
  cart: {
    buffetId: '',
    items: [],
  },
  skyboxHeader: {
    title: 'MVM DOME Skybox<br>étel előrendelés',
    description:
      'Aliquam sed quam vel massa mollis iaculis a ultricies tellus. Proin commodo lacinia erat. Fusce cursus dictum dui, et bibendum elit luctus et.',
    background: '',
    backLink: '',
    backLinkText: '',
  },
  events: [],
  buffets: [],
  groupedEvents: [],
  pendingOrders: [],
  introSkipped: false,
  selectedFloor: -1,
  orderType: 'buffet',
  sectorLocationPairs: [],
  selectedProductType: 'food',
  // invoice data below
  invoiceEmail: '',
  invoiceName: '',
  invoicePostalCode: '',
  invoiceCity: '',
  invoiceAddress: '',
  invoiceCountry: '',
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: getInitialState(),
  getters: {
    pageViewed: (state) => (pageName) => {
      return state.viewedPages.includes(pageName);
    },
    getProductFromCart: (state) => (productId) => {
      return state.cart.items.find((item) => item.id === productId);
    },
    totalPrice(state) {
      let totalPrice = 0;

      state.cart.items.forEach((item) => (totalPrice += item.price * item.quantity));

      return totalPrice;
    },
    totalNetPrice(state) {
      let totalPrice = 0;

      state.cart.items.forEach((item) => {
        totalPrice += (item.price / (item.vat + 100)) * 100 * item.quantity;
      });

      return totalPrice;
    },
    totalVatAmount(state) {
      let vatAmount = 0;

      state.cart.items.forEach((item) => {
        let gross = item.price * item.quantity;
        let net = (item.price / (item.vat + 100)) * 100 * item.quantity;

        vatAmount += gross - net;
      });

      return vatAmount;
    },
    getSkyboxHeader(state) {
      return state.skyboxHeader;
    },
    getEventById: (state) => (id) => {
      return state.events.find((data) => data.id === id);
    },
    getSelectedBuffet: (state) => {
      return state.events.find((data) => data.id === state.cart.buffetId);
    },
    getStoredEvent: (state) => {
      return state.events.find((data) => data.id === state.cart.buffetId);
    },
    getStoredEventName: (state) => {
      const data = state.events.find((data) => data.id === state.cart.buffetId);

      if (data) {
        return data.event.name;
      }

      return '';
    },
    getEventsByMonth: (state) => (year, monthName) => {
      let data = [];

      if (state.events) {
        data = state.events.filter(
          (event) =>
            parseInt(event.event.year) == year && event.event.month === monthName && typeof event.event.active !== undefined && event.event.active === true
        );
      }

      return data;
    },
    hasEventInMonth: (state) => (year, monthName) => {
      return state.events.find(
        (event) =>
          parseInt(event.event.year) == year && event.event.month === monthName && typeof event.event.active !== undefined && event.event.active === true
      )
        ? true
        : false;
    },
    hasPendingOrders(state) {
      return state.pendingOrders.length > 0;
    },
    getSavedLocale() {
      return window.localStorage.getItem('locale') || 'hu';
    },
    allBuffetsClosed(state) {
      return !state.buffets.find(buffet => buffet.open);
    },
    visibleBuffetsByBuffetGroup: (state) => (id) => {
      const relatedLocations =
      state.sectorLocationPairs[id];      

      let result = [];
      if (
        id == "Bejárat" ||
        id == "Küzdőtér"
      ) {
        result = state.buffets.filter(
          (buffet) =>
            relatedLocations.includes(buffet.location) &&
            typeof buffet.open !== undefined &&
            buffet.open === true
        );        
      } else {
        result = state.buffets.filter(
          (buffet) =>
            relatedLocations.includes(parseInt(buffet.location)) &&
            typeof buffet.open !== undefined &&            
            buffet.open === true
        );
      }      

      return result;
    },
    visibleBuffets(state, getters) {
      return getters.visibleBuffetsByBuffetGroup(state.selectedBuffetGroup);      
    },
  },
  mutations: {
    resetStore(state) {
      // eslint-disable-line
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getInitialState());
    },
    pageViewed(state, pageName) {
      state.viewedPages.push(pageName);
    },
    storeUserData(state, { token, id }) {
      state.userToken = token;
      state.userId = id;
    },
    changeSelectedBufferId(state, buffetId) {
      state.cart.buffetId = buffetId;
    },
    changeSelectedBuffetGroup(state, groupKey) {
      state.selectedBuffetGroup = groupKey;
    },
    updateProductQuantityInCart(state, { product, quantity }) {
      let productIndex = state.cart.items.findIndex((p) => p.id === product.id);
      if (productIndex === -1) {
        state.cart.items.unshift({ ...product, quantity });
      } else {
        Vue.set(state.cart.items[productIndex], 'quantity', quantity);
      }

      if (quantity === 0) {
        state.cart.items.splice(productIndex, 1);
      }
    },
    updateCart(state, cart) {
      Vue.set(state, 'cart', cart);
    },
    dropCart(state) {
      Vue.set(state, 'cart', { buffetId: '', items: [] });
    },
    setEvents(state, data) {
      if (data) {
        data = data.filter((row) => row.type === 'skybox' || row.type === 'vip');
      } else {
        data = [];
      }

      state.events = data;

      if (state.events) {
        state.events.map((event) => {
          event.event.month = moment(event.event.date).format('MMMM');
          event.event.year = moment(event.event.date).format('YYYY');
        });
      }
    },
    setBuffets(state, data) {
      state.buffets = data;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setSkyboxTitle(state, value) {
      state.skyboxHeader.title = value;
    },
    setSkyboxText(state, value) {
      state.skyboxHeader.description = value;
    },
    setSkyboxLink(state, value) {
      state.skyboxHeader.backLink = value;
    },
    setSkyboxLinkText(state, value) {
      state.skyboxHeader.backLinkText = value;
    },
    removeHeaderLink(state) {
      state.skyboxHeader.backLink = '';
      state.skyboxHeader.backLinkText = '';
    },
    hideNavigation(state) {
      state.navigationVisible = false;
    },
    showNavigation(state) {
      state.navigationVisible = true;
    },
    setMvmId(state, value) {
      state.mvmId = value;
    },
    setEmbedded(state, value) {
      state.embedded = value;
    },
    setInvoiceData(state, value) {
      state.invoiceEmail = value.invoiceEmail;
      state.invoiceName = value.invoiceName;
      state.invoicePostalCode = value.invoicePostalCode;
      state.invoiceCity = value.invoiceCity;
      state.invoiceAddress = value.invoiceAddress;
      state.invoiceCountry = value.invoiceCountry;
    },
    setIntroSkipped(state, value) {
      state.introSkipped = value;
    },
    setFloor(state, value) {
      state.selectedFloor = parseInt(value);
    },
    setShopType(state, value) {
      state.orderType = value;
    },
    setPendingOrders(state, data) {
      state.pendingOrders = data;
    },
    setApploading(state, value) {
      state.appLoading = value;
    },
    setSectorLocations(state, value) {
      state.sectorLocationPairs = value;
    },
    setProductType(state, value) {
      state.selectedProductType = value;
    },
  },
  actions: {
    resetStore({ commit }) {
      commit('resetStore');
    },
    pageViewed({ getters, commit }, pageName) {
      if (!getters.pageViewed(pageName)) {
        commit('pageViewed', pageName);
      }
    },
    storeUserData({ commit }, data) {
      commit('storeUserData', data);
    },
    selectBuffet({ commit }, buffetId) {
      commit('changeSelectedBufferId', buffetId);
    },
    selectBuffetGroup({ commit }, groupKey) {
      commit('changeSelectedBuffetGroup', groupKey);
    },
    updateProductQuantityInCart({ commit }, { product, quantity }) {
      commit('updateProductQuantityInCart', { product, quantity });
    },
    updateCart({ commit }, cart) {
      commit('updateCart', cart);
      return cart;
    },
    dropCart({ commit }) {
      commit('dropCart');
    },
    resetCart({ commit, state }) {
      commit('updateCart', { buffetId: state.cart.buffetId, items: [] });
    },
    loadEvents({ state, commit }) {
      getBuffets(state.mvmId)
        .then((result) => {
          commit('setEvents', result);
          commit('setLoading', false);
        })
        .catch((err) => {
          console.log(err);
          commit('setLoading', false);
        });
    },
    createPostMessage(postMessageData) {
      console.log(postMessageData);
      /*
			window.parent.postMessage({
				orderstride: postMessageData
			}, '*');
			*/
    },
    // data: { orderId, grossPrice, eventName }
    postMessageCart({ state, dispatch }, data) {
      if (state.cart.items) {
        let orderData = {
          orderId: data.orderId,
          grossPrice: data.grossPrice,
          eventName: data.eventName,
          items: state.cart.items.map((it) => ({
            title: it.title,
            grossPrice: it.price,
            quantity: it.quantity,
            type: it.type,
            vat: it.vat,
          })),
        };

        if (state.invoiceEmail) {
          let paymentRedirectUrl;
          const pruBase = `${config.self}order/lang/hu/mvmid/${encodeURIComponent(state.mvmId)}/orderid/${encodeURIComponent(data.orderId)}`;
          if (state.embedded == '1') {
            const pruB64 = bytesToBase64(pruBase);
            paymentRedirectUrl = `mvm-dome://orderstride/${pruB64}`;
          } else {
            paymentRedirectUrl = pruBase;
          }

          const body = {
            orderstride: {
              email: state.invoiceEmail,
              name: state.invoiceName,
              postalcode: state.invoicePostalCode,
              city: state.invoiceCity,
              address: state.invoiceAddress,
              country: state.invoiceCountry,
              paymentRedirectUrl,
              statusCallbackUrl: `${config.api}payment-webhooks/${encodeURIComponent(data.orderId)}`,
              // -----
              ...orderData,
            },
          };
          const bodyString = JSON.stringify(body);
          fetch(config.payment, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: bodyString,
          })
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              const parsed = JSON.parse(data);
              if (parsed.success && parsed.data.redirect) {
                document.location.href = parsed.data.redirect;
                dispatch('resetCart');
              }
            });
        } else {
          const postMessageData = {
            orderstride: orderData,
          };
          console.log(postMessageData);
          window.parent.postMessage(postMessageData, '*');
          dispatch('resetCart');
        }
      }
    },
    loadPendingOrders({ state, commit }) {
      getPendingOrders(state.mvmId)
        .then((result) => {
          commit('setPendingOrders', result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadSectorLocations({ commit }) {
      getSectorLocations()
        .then((result) => {
          commit('setSectorLocations', result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
