const config = require('../../config.' + process.env.NODE_ENV).default;
export function freeRequest (url, {method = 'GET', data, headers = {'Content-Type': 'application/json'}} = {}) {
	let request = {
		method,
		headers
	};
	if (data) {
		request.body = JSON.stringify(data);
	}
	return fetch(url, request);
}
export function apiCall (url, {method = 'GET', data, headers = {'Content-Type': 'application/json'}} = {}) {
	return freeRequest(config.api + url, {method, data, headers})
		.then(res => {
			const contentType = res.headers.get('content-type');
			return contentType && contentType.includes('application/json') ? res.json() : {}
		}).then(res => {
			if (res.error) {
				throw res.error;
			} else {
				return res;
			}
		});
}
export const getAuthorizedApiCall = (token, url, {method = 'GET', data, headers = {
		'Content-Type': 'application/json',
		'x-orderstride-mvmid': token,
	}} = {}) => apiCall(url, {method, data, headers});